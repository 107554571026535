import React, {  useState } from "react"
import { useWeddingAdmin } from "../../context/WeddingAdminProvider"
import { List, ListItem, IconButton, ListItemText, ToggleButtonGroup, ToggleButton, Box, Grid2, TextField, Button } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { GiftOption, Wedding } from "../../utils/Wedding";

export const Gifts: React.FC<any> = ()=>{
    const { wedding, weddingId, setWedding, refetch } = useWeddingAdmin();
    const [gift, setGift] = useState<GiftOption>({
        exclusive: true,
        name: ''
    })
    const [error, setError] = useState(false)

    const handleChangeGiftName = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
        error && setError(false)
        setGift(prev => ({...prev, name: e.target.value }))
    }

    const handleChangeGiftExclusive = ()=>{
        setGift(prev => ({...prev, exclusive: !prev.exclusive }))
    }

    const handleAddGift = async()=>{
        if (!gift.name) setError(true)
        // const newGift = {name: gift.name, isExclusive: gift.exclusive}
        fetch(`/api/weddings/${weddingId}/gifts`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(gift)
        }).then((response)=>{
            if (response.ok) return response.json()
            return
        }).then((dataResponse)=>{
            if (dataResponse?.giftId){
                // Push GiftOption to Wedding
                const newGift: GiftOption = {...gift, id: dataResponse.giftId}
                const updatedWedding: Wedding | null = wedding
                ? {
                    ...wedding,
                    giftOptions: [...(wedding.giftOptions || []), newGift],
                } : null;
                setWedding(updatedWedding);
                return
            }
            // Error, refetch
            refetch()
        }).catch((err)=>{
            alert(err)
        }).finally(()=>{
            setGift({
                exclusive: true,
                name: ''
            })
        })
    }

    const handleToggleExclusive = async(giftId?: number)=>{
        if (!giftId) return
        fetch(`/api/weddings/${weddingId}/gifts/${giftId}/exclusive/toggle`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json'
                }
        }).then((response)=>{
            if (response.ok){
                const oldGift: GiftOption | undefined = wedding?.giftOptions.find(g => g.id === giftId)

                if (!oldGift){
                    refetch()
                    return
                }

                const updatedGiftOptions: GiftOption[] = wedding?.giftOptions.map(gift =>
                    gift.id === giftId ? { ...gift, exclusive: !gift.exclusive } : gift
                ) || [];
            
                const updatedWedding: Wedding | null = wedding ? {...wedding, giftOptions: updatedGiftOptions, } : null;
                setWedding(updatedWedding);
                return
            }
            // Error, refetch
            refetch()
        }).catch((err)=>{
            alert(err)
        }).finally(()=>{
            
        })
    }
    
    const handleRemoveGift = async(giftId?: number)=>{
        if (!giftId) return
        fetch(`/api/weddings/${weddingId}/gifts/${giftId}`, {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json'
                }
        }).then((response)=>{
            if (response.ok) {
                const oldGift = wedding?.giftOptions.find(g => g.id === giftId);
                if (!oldGift) {
                    refetch();
                    return;
                }
    
                const newGiftOptions = wedding?.giftOptions.filter(go => go.id !== giftId) || [];
                const updatedWedding: Wedding | null = wedding
                    ? {
                          ...wedding,
                          giftOptions: newGiftOptions,
                      }
                    : null;
    
                setWedding(updatedWedding);
            } else {
                refetch();
            }
        }).catch((err)=>{
            alert(err)
        })
    }

   

    return (
        <Box sx={{maxWidth: '700px'}}>
            
            <Grid2 container spacing={3}>
                <Box display={"inline-flex"} gap={2}>
                    <TextField
                        fullWidth
                        label="Gift Name"
                        name="giftname"
                        value={gift.name}
                        onChange={handleChangeGiftName}
                        error={error}
                        required
                    />
                    <ToggleButtonGroup exclusive
                        value={!!gift.exclusive}   
                        onChange={handleChangeGiftExclusive}                 
                    >
                        <ToggleButton value={true}>Exclusive</ToggleButton>
                        <ToggleButton value={false}>Multiple</ToggleButton>
                    </ToggleButtonGroup>
                    <Button variant="contained"
                            color="primary"
                            onClick={handleAddGift}>
                        Add
                    </Button>
                </Box>
            </Grid2>

            <List dense sx={{bgcolor: 'background.paper'}}>
                {wedding?.giftOptions?.map((g, i)=>{
                    return (
                        <ListItem key={i}
                            secondaryAction={
                                <IconButton onClick={()=>handleRemoveGift(g.id)} edge="end" aria-label="delete">
                                    <DeleteIcon />
                                </IconButton>
                            }
                        >
                            <ListItemText primary={g.name} />
                            <ToggleButtonGroup exclusive value={!!g.exclusive} onChange={()=>handleToggleExclusive(g.id)}>
                                <ToggleButton value={true}>Exclusive</ToggleButton>
                                <ToggleButton value={false}>Multiple</ToggleButton>
                            </ToggleButtonGroup>
                        </ListItem>
                    )
                })}
            </List>
        </Box>
    )
}