import React, { ReactNode } from "react"
import { WeddingAdminProvider } from "../../context/WeddingAdminProvider"
import { Box } from "@mui/material";

interface IProps {
    children: ReactNode;
}

export const WeddingAdminLayout: React.FC<IProps> = ({children})=>{
    return (
        <WeddingAdminProvider>
            {children}
        </WeddingAdminProvider>

    )
}


