import { Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React from 'react'
import { GiftOption, Wedding } from '../../../utils/Wedding'
import { useWedding } from '../../../context/WeddingProvider';

interface IProps {
    gifts: GiftOption[];
}

export const GiftList: React.FC<IProps> = ({gifts})=>{
    const { wedding, weddingId, setWedding } = useWedding()

    const handleSelect = (giftId?: number )=> () =>{
        if (!weddingId) return
        if (!wedding) return

        fetch(`/api/weddings/${weddingId}/gifts/${giftId}/buyers`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response)=>{
            if (response.ok) {
                const oldGift = wedding?.giftOptions.find(g => g.id === giftId);
                if (!oldGift) {
                    // refetch();
                    return;
                }
    
                const updatedGiftOptions: GiftOption[] = wedding?.giftOptions.map(gift => {
                    if(gift.id === giftId){
                       const newGift = { ...gift, 
                            purchasedByMe: gift.purchasedByMe ? false : true,
                        };
                        if(gift.exclusive){
                            newGift.available = !gift.available
                        }
                        return newGift;
                    }
                    return gift;
                });
            
                const updatedWedding: Wedding | null = wedding ? {...wedding, giftOptions: updatedGiftOptions, } : null;
                setWedding(updatedWedding);
                return
            } else {
                // refetch();
            }
        }).catch((err) => {
            alert('erro ao selecionar')
        })
    }

    return (
        <List>
            {wedding?.giftOptions?.map((gift, i) => {
                const purchasedByOther = !gift.available && !gift.purchasedByMe
                const labelId = `checkbox-list-label-${gift.name}`;
                return (
                    <ListItem key={i} disablePadding>
                        <ListItemButton role={undefined} onClick={handleSelect(gift.id)} dense disabled={purchasedByOther}>
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={gift.purchasedByMe || !gift.available} 

                                    disabled={purchasedByOther}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </ListItemIcon>
                            <ListItemText id={labelId} primary={gift.name} />
                        </ListItemButton>
                    </ListItem>
                )
            })}
        </List>
    )
}