import React, { useCallback, useState } from "react";
import {
    TextField,
    Button,
    Typography,
    Box,
    Grid2,
    useMediaQuery,
    useTheme,
    Card,
    CardContent,
    AvatarGroup,
    Avatar,
    IconButton,
} from "@mui/material";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { blobToUrl, fileToBase64 } from "../../utils/blobImage";
import { ResponsiblePaper } from "../wedding/components/ResponsiblePaper";
import { Group, Guest, Wedding } from "../../utils/Wedding";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

export const CreateWedding: React.FC = () => {
    const theme = useTheme();
    // const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [errors, setErrors] = useState({
        name: false,
        displayUrl: false,
    });
    
    const [wedding, setWedding] = useState<Wedding>({
        name: "",
        displayUrl: "",
        description: "",
        backgroundPicture: null,
        infos: "",
        weddingAddress: "",
        weddingDate: null,
        groups: [],
        ageOptions: [],
        foodOptions: [],
        questions: [],
        giftOptions: [],
        lists: [],
    });

    const addWedding = useCallback(async()=>{
        fetch("/api/weddings", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(wedding)
        }).then((response)=>{
            if (response.ok){
                response.json()
                alert("created")
                return
            }
            alert("error" + response.status)
        }).then((dataResponse)=>{
            
        }).catch((err)=>{
            alert(err)
        })
    }, [wedding])

    const [pictureUrl, setPictureUrl] = useState("");

    const validateFields = (): boolean => {
        const newErrors = {
            name: !wedding.name.trim(),
            displayUrl: !wedding.displayUrl.trim(),
        };
        setErrors(newErrors);
        return !Object.values(newErrors).includes(true);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setWedding((prev) => ({ ...prev, [name]: value }));
        setErrors(prev => ({...prev, [name]: false}))
    };

    const handleQuillChange = (field: "description" | "infos", value: string) => {
        setWedding((prev) => ({ ...prev, [field]: value }));
    };

    const handleSubmit = () => {
        if (!validateFields()) return
        addWedding()
    };

    const handleUpload = async (e: any) => {
        const file = e.target.files?.[0];
        if (!file) return;

        try {
            const base64String = await fileToBase64(file);
            setWedding((prev) => ({ ...prev, backgroundPicture: base64String }));
            setPictureUrl(blobToUrl(file) || '')
        } catch (error) {
        }
    };

    function gerarCodigo() {
        const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let codigo = '';
        for (let i = 0; i < 4; i++) {
            const indiceAleatorio = Math.floor(Math.random() * caracteres.length);
            codigo += caracteres[indiceAleatorio];
        }
        return codigo;
    }

    const handleAddGroup = ()=>{
        // Add atleast 1 guest standard
        const guest: Guest[] = [
            {
                name: `Person 1`
            }
        ]

        const countGroups = wedding.groups?.length || 0
        const group: Group = {
            name: `Invitation ${countGroups + 1}`,
            entryCode: gerarCodigo(),
            guests: guest
        }
        setWedding(prev => ({
            ...prev,
            groups: [...(prev.groups || []), group]
        }))
    }

    const handleAddGuestToGroup = (index: number) => {
        setWedding(prev => ({
            ...prev,
            groups: prev.groups?.map((group, i) =>
                i === index
                    ? {
                        ...group,
                        guests: [
                            ...(group.guests || []),
                            { name: `Person ${(group.guests?.length || 0) + 1}` }
                        ]
                    }
                    : group
            )
        }));
    };
    
    const handleRemoveGuestFromGroup = (index: number) => {
        setWedding(prev => ({
            ...prev,
            groups: prev.groups?.map((group, i) =>
                i === index
                    ? {
                        ...group,
                        guests: group.guests?.slice(0, -1) || []
                    }
                    : group
            )
        }));
    };

    const handleRemoveGroup = (index: number) => {
        setWedding(prev => ({
            ...prev,
            groups: prev.groups?.filter((_, i) => i !== index)
        }));
    };
    

    return (
        <Box sx={{ padding: isMobile ? 0 : 4 }}>
            <Typography variant="h4" gutterBottom>
                Create Wedding
            </Typography>
            <ResponsiblePaper autoMargin={!!isMobile} fullWidth widthReduction={isMobile ? '20px' : ''} elevation={3} style={{ padding: isMobile ? '10px' : '15px', maxWidth: '1000px'  }}>
                <Grid2 container spacing={3}>
                    <Grid2 size={12}>
                        <TextField
                            fullWidth
                            label="Name"
                            name="name"
                            value={wedding.name}
                            onChange={handleInputChange}
                            error={errors.name}
                            required
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <TextField
                            fullWidth
                            label="Display URL"
                            name="displayUrl"
                            value={wedding.displayUrl}
                            error={errors.displayUrl}
                            onChange={handleInputChange}
                        />
                    </Grid2>
                    <Grid2 size={12} width={'100%'} maxWidth={1000} minWidth={300}>
                        <Typography variant="h6">Description</Typography>
                        <ReactQuill
                            theme="snow"
                            value={wedding.description}
                            onChange={(value) => handleQuillChange("description", value)}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <Typography variant="h6">Infos</Typography>
                        <ReactQuill 
                            theme="snow"
                            value={wedding.infos}
                            onChange={(value) => handleQuillChange("infos", value)}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <Button variant="contained" component="label">
                            Upload Background Picture
                            <input
                                type="file"
                                hidden
                                onChange={handleUpload}
                            />
                        </Button>
                    </Grid2>
                    <Grid2>
                        <img src={pictureUrl} alt="" height={"100px"}/>
                    </Grid2>
                    <Grid2 size={12}>
                        <Typography variant="h6">Invitations</Typography>
                        <Button onClick={handleAddGroup}>Add invitation</Button>
                        <Box display={'flex'} flexDirection={'column'} gap={2} sx={{overflowY: 'auto', maxHeight: '60dvh'}}>
                            {wedding.groups?.map((group, index)=>{
                                return (
                                    <Box  sx={{ minWidth: 275 }} key={index}>
                                        <Card variant="outlined">
                                            <CardContent>
                                                <Box display={'inline-flex'} justifyContent={'space-between'} sx={{width: '100%'}}>
                                                    <Typography variant="h5" component="div">{group.name}</Typography>
                                                    <Box display={'inline-flex'} alignItems={'center'}>
                                                        <Typography sx={{ color: 'text.secondary' }}>Add/Remove Guest:</Typography>
                                                        <IconButton onClick={()=>handleAddGuestToGroup(index)}>
                                                            <AddIcon />
                                                        </IconButton>
                                                        <IconButton onClick={()=>handleRemoveGuestFromGroup(index)}>
                                                            <RemoveIcon />
                                                        </IconButton>
                                                    </Box>
                                                </Box>
                                                <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>{group.entryCode}</Typography>
                                                <Box display={'inline-flex'} justifyContent={'space-between'} sx={{width: '100%'}}>
                                                    <AvatarGroup max={5}>
                                                        {group.guests?.map((guest, i)=>{
                                                            return <Avatar key={i} alt="" src="/broken-image.jpg"></Avatar>
                                                        })}
                                                    </AvatarGroup>
                                                    <Button onClick={()=>handleRemoveGroup(index)} size="small">Remove</Button>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Box>
                                )
                            })}
                        </Box>
                    </Grid2>
                    <Grid2 size={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            Save Wedding
                        </Button>
                    </Grid2>
                </Grid2>
            </ResponsiblePaper>
        </Box>
    );
};

