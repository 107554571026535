import { createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#1976e3',
        },
        secondary: {
            main: '#d32f2f',
        },
        background: {
            default: '#f5f5f5',
            paper: '#ffffff',
        },
        text: {
            primary: '#000000',
        },
        navbar: {
            background: '#e8e6c5',
            text: '#2e2e2e'
        },
        floatingButton: {
            background: '#535353',
            color: '#ffffff'
        },
        description: {
            background: '#fff8e3',
            color: '#2a2a2a'
        },
        homepage: {
            fontFamily: 'Emilys Candy, Arial, sans-serif',
            color: '#000'
        }
    },
});

declare module "@mui/material/styles" {
    interface Palette {
        navbar?: {
            background: string;
            text: string;
        };
        floatingButton: {
            background: string;
            color: string;
        }
        description: {
            background: string;
            color: string;
        },
        homepage: {
            fontFamily: string,
            color: string
        }
    }
    interface PaletteOptions {
        navbar?: {
            background: string;
            text: string;
        };
        floatingButton: {
            background: string;
            color: string;
        }
        description: {
            background: string;
            color: string;
        },
        homepage: {
            fontFamily: string,
            color: string
        }
    }
}
  

export default lightTheme;