import { Avatar, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@mui/material"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom";
import { useWeddingAdmin } from "../context/WeddingAdminProvider";
import { Link } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';
import SegmentIcon from '@mui/icons-material/Segment';
import InfoIcon from '@mui/icons-material/Info';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';

type Items = {
    label: string;
    endingUrl: string;
    icon: JSX.Element;
}

export const AdminSidebar: React.FC<any> = ()=>{
    const { allWeddings, wedding } = useWeddingAdmin()
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);
    const handleClickOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const items: Items[] = [
        {label: "Dashboard", endingUrl: "", icon: <DashboardIcon />},
        {label: "Welcome Text", endingUrl: "description", icon: <SegmentIcon />},
        {label: "Infos", endingUrl: "information", icon: <InfoIcon />},
        {label: "Invitations", endingUrl: "invitations", icon: <MarkAsUnreadIcon />},
        {label: "Gift List", endingUrl: "giftlist", icon: <CardGiftcardIcon />},
    ]


    return (
        <>
            <Drawer
                variant="persistent"
                anchor="left"
                open={true}
                
            >
                <List sx={{width: "14rem"}}>
                    <ListItem disablePadding>
                        <ListItemButton sx={{justifyContent: "space-between"}} onClick={handleClickOpenMenu}>
                            <Avatar src={`/api/weddings/${wedding?.displayUrl}/background`}/>
                            <Typography variant="body2" noWrap>{wedding?.name}</Typography>
                            <KeyboardArrowDownIcon />
                        </ListItemButton>
                    </ListItem>
                    {items.map((i, index)=>{
                        return (
                            <ListItem key={index} disablePadding>
                                <ListItemButton onClick={()=> navigate(`/weddings/${wedding?.displayUrl}/${i.endingUrl}`)}>
                                    <ListItemIcon>
                                        {i.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={i.label}/>
                                </ListItemButton>
                            </ListItem>
                        )
                    })}
                </List>
            </Drawer>
            <Menu
                
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >   
                {allWeddings?.filter(wd => wd.id !== wedding?.id).map((w, i)=>{
                    return (
                        <MenuItem key={i} component={Link} to={`/weddings/${w.displayUrl}`} onClick={handleClose} disableRipple>
                            <Avatar src={`/api/weddings/${w?.displayUrl}/background`}/>
                            <Typography variant="body2" noWrap>{w?.name}</Typography>
                        </MenuItem>
                    )    
                })}
                {allWeddings?.filter(wd => wd.id !== wedding?.id).length > 0 && <Divider />}
                <MenuItem component={Link} to={"/weddings/create"} onClick={handleClose} disableRipple>
                
                    <AddIcon />
                    Create new wedding
                </MenuItem>
            </Menu>
        </>
    )
}