import React, { useEffect, useState } from "react"
import { useWeddingAdmin } from "../../context/WeddingAdminProvider"



export const Dashboard = ()=>{

    const { wedding, weddingId } = useWeddingAdmin()
    const urlPicture: string | undefined = weddingId ? `/api/weddings/${weddingId}/background` : "/broken-image.jpg"

    return (
        <>
            <img height={"100px"} src={urlPicture} alt="" />
            <pre>DASHBOARD {JSON.stringify(wedding)}</pre>
        </>
    )
}