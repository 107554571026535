import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { Wedding } from "../utils/Wedding";
import { useAuth } from "./AuthProvider";
import { AdminSidebar } from "../components/AdminSidebar";
import { Box } from "@mui/material";

interface IWeddingContext {
    allWeddings: Wedding[];
    wedding: Wedding | null;
    isLoading: boolean;
    error: any;
    weddingId?: string;
    setWedding: (wedding: Wedding | null)=>void;
    refetch: ()=>void;
}

const weddingAdminContext = createContext<IWeddingContext | null>(null)

export const useWeddingAdmin = () =>{
    const context = useContext(weddingAdminContext);
    if (!context) {
        throw new Error("useWeddingAdminProvider must be used within a WeddingAdminProvider");
    }
    return context;
}

interface IProps {
    children: ReactNode;
}

export const WeddingAdminProvider: React.FC<IProps> = ({children})=>{
    const { weddingId } = useParams()
    const navigate = useNavigate()
    const { user, isLoading: isLoadingUser } = useAuth()
    const [wedding, setWedding] = useState<Wedding | null>(null);
    const [allWeddings, setAllWeddings] = useState<Wedding[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [refetchTrigger, setRefetchTrigger] = useState<boolean>(true);
    const [error, setError] = useState<any>(null);
    
    // If not authenticated
    useEffect(() => {
        if (!user && !isLoadingUser) {
            navigate("/signin")
        }
    }, [user, navigate, isLoadingUser]);
    
    const fetchWeddings = useCallback(async()=>{
        try {
            const endpoint = `/api/weddings`;
            const response = await fetch(endpoint, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            if (!response.ok) { 
                throw Error(response.status.toString());
            } 
            const data: Wedding[] = await response.json();
            setAllWeddings(data);
            setError(null);
                
        } catch(err) {
            setIsLoading(false);
            if (err instanceof Error) {
                setError(err.message || 'An unknown error occurred');
            } else {
                setError('An unknown error occurred');
            }
        } finally {
            setIsLoading(false);
        }
    }, [])

    // If authenticaded, fetch wedding
    useEffect(() => {
        if (user) {
            fetchWeddings()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [weddingId, user, navigate, refetchTrigger])

    // Redirect from /weddings to first wedding. Use /weddings/${weddingId}
    useEffect(()=>{
        // If weddings = 0, render Welcome Page
        if (isLoading || error || allWeddings.length < 1) return

        // No weddingUrl => redirect to first
        if (!weddingId){
            navigate(`/weddings/${allWeddings[0].displayUrl}`)
            setWedding(allWeddings[0])
        }

        // WeddingUrl provided, render page
        if (weddingId){
            const w = allWeddings.findIndex(wd => wd.displayUrl === weddingId)
            w > -1 && setWedding(allWeddings[w])
        }

    },[allWeddings, weddingId])

    const values: IWeddingContext = {
        refetch: ()=> setRefetchTrigger(prev => !prev),
        allWeddings,
        wedding,
        isLoading,
        error,
        weddingId,
        setWedding
    }

    
    if (isLoading) return <>loading...</>
    if (error) return <>Error wedding context {error}</>
    
    // Double check / Unused, catched on useEffect to navigate to creation page
    if (!allWeddings.length && weddingId) return <>Wedding not found</>

    // If weddingId not found
    if (!wedding && weddingId) return <>Wedding with wedding id not found</>

    // Show sidebar always except on WelcomePage
    // Remove margin on WelcomePage
    return (
        <weddingAdminContext.Provider value={values}>
            {allWeddings.length > 0 && 
                <AdminSidebar />
            }
            <Box marginLeft={allWeddings.length > 0 ? "14rem" : "0"}>
                {children}
            </Box>
        </weddingAdminContext.Provider>
    )
}