import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { WeddingAdminLayout } from '../pages/administration/WeddingAdminLayout';
import { Dashboard } from '../pages/administration/Dashboard';
import { WelcomePage } from '../pages/administration/WelcomePage';
import { CreateWeddingLayout } from '../pages/create/CreateWeddingLayout';
import { Invitations } from '../pages/administration/Invitations';
import { Information } from '../pages/administration/Information';
import { Description } from '../pages/administration/Description';
import { Gifts } from '../pages/administration/Gifts';

const AdminRoutes: React.FC = () => {
    return (
            <Routes>
                <Route path="/create" element={<CreateWeddingLayout />} />
                <Route path="/:weddingId/invitations" element={
                    <WeddingAdminLayout>
                        <Invitations />
                    </WeddingAdminLayout>
                } />
                <Route path="/:weddingId/information" element={
                    <WeddingAdminLayout>
                        <Information />
                    </WeddingAdminLayout>
                } />
                <Route path="/:weddingId/description" element={
                    <WeddingAdminLayout>
                        <Description />
                    </WeddingAdminLayout>
                } />
                <Route path="/:weddingId/giftlist" element={
                    <WeddingAdminLayout>
                        <Gifts />
                    </WeddingAdminLayout>
                } />
                <Route path="/:weddingId" element={
                    <WeddingAdminLayout>
                        <Dashboard />
                    </WeddingAdminLayout>
                } />
                <Route path="/" element={
                    <WeddingAdminLayout>
                        <WelcomePage />
                    </WeddingAdminLayout>
                } />
            </Routes>
    );
};

export default AdminRoutes;
