import { Typography, useMediaQuery, useTheme } from "@mui/material";
import styles from "../WeddingHomepage.module.css"

interface IProps {
    url?: string;
    title?: string;
}

export const PictureWithName: React.FC<IProps> = ({url, title})=>{
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"))
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    return (
        <div 
            className={styles.backgroundPictureContainer}
            style={{
                // backgroundImage: url ? `url(${url})` : '',
                margin: isMobile ? '10px' : "0 60px",
                // maxHeight: isMobile ? '' : '70dvh'
            }}
        >   
            <img className={styles.backgroundPicture} style={{border: isMobile ? '10px solid white' : '20px solid white'}} src={url} alt="wedding"/>
            <span>
                <Typography variant={isMobile ? "h3" : isTablet ? 'h2' : 'h1'} fontFamily={"Bilbo Swash Caps, cursive"}>{title || 'Kein Titel'}</Typography>
            </span>
            {/* {!url && <span>Picture Not Found</span>} */}
        </div>
    )
}